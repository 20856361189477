<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData" add @add="addNew">
    </Filter>
    <Table ref="tableId" :tableData="tableDataList" :total="total" @update="updatePage" tooltip-effect="light">
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column prop="id" label="id" />
      <el-table-column prop="name" label="角色" />
      <!-- <el-table-column prop="menus" label="菜单" >
        <template #default="{row}">{{ JSON.stringify(row.menus) }}</template>
      </el-table-column> -->
      <el-table-column label="操作"  width="100" fixed="right">
        <template #default="{row}">
          <el-button type="primary" plain size="mini" @click="editMenu(row)">编辑菜单</el-button>
          <!-- <el-button type="danger" plain size="mini" @click="deleteRole">删除角色</el-button> -->
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="编辑菜单" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-tree ref="menuTree" :data="treeData" node-key="path" show-checkbox />
      <template #footer>
        <el-button type="primary" size="small" @click="setRoleMenu">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, nextTick } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";

import Table from "@/components/table/index";
import Filter from "@/components/table/filter";

import { common } from "@/common/mixin";
import { request } from "@/utils/request";
import { menus } from "@/router";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const state = reactive({
      params: [{
        key: "roleName",
        value: "",
        label: "角色"
      }],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      dialogFormVisible: false,
      menuTree: null,
      treeData: menus, // 树
      roleName: '', // 
      roleId: null // 编辑菜单
    })
    // watch(() => state.query,
    //   (cur, pre) => {
    //     console.log("新值:", cur, "老值:", pre);
    //     if (state.query.tag) {
    //       state.query[state.query.tag] = true
    //     }
    //   },
    //   { immediate: true, deep: true }
    // )
    const setupInit = () => {
      try {
        console.log('menus', menus)
        getData()
      } catch (error) {
         console.error(error)
      }
    }
    const getData = async () => {
      try {
        const res = await request("getRoleList", {
          ...state.query,
          page: state.page,
          size: state.size,
        })
        state.tableDataList = res.records
        state.total = res.total
      } catch (error) {
        console.error(error)
      }
    }
    const searchData = async (data) => {
      console.log('searchData', data)
      state.query = data;
      getData()
    }
    const updatePage = (page, size) => {
      state.page = page || 1
      state.size = size || 10
      getData()
    }
    const addRole = async (name='') => {
      try {
        await request("addRole", {
          name: name
        })
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "角色添加成功"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const addNew = () => {
      ElMessageBox.prompt(`请输入角色名`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: state.roleName,
        inputType: 'text',
        inputPattern: /^[^\s]+$/,
        inputErrorMessage: '输入无效'
      }).then(({ value }) => {
        console.log('prompt', value)
        value && addRole(value)
      }).catch(() => {
      })
    }
    const editMenu = async (row) => {
      row.menus = await request('getRoleMenuList', { roleId: row.id})
      state.dialogFormVisible = true
      const checkedKeys = []
      if (row.menus) {
        row.menus.forEach((menu) => {
          menu.children && menu.children.forEach((sub) => {
            checkedKeys.push(sub.path)
          })
        })
      }
      nextTick(()=>{
        state.menuTree.setCheckedKeys(checkedKeys)
      })
      state.roleId = row.id
    }
    const setRoleMenu = async () => {
      try {
        const checkedKeys = state.menuTree.getCheckedKeys() || []
        console.log('selectMenus', checkedKeys)
        const checkedMenus = []
        state.treeData.forEach(menu => {
          if (checkedKeys.includes(menu.path)) {
            checkedMenus.push(menu)
          } else {
            let children = []
            menu.children.forEach((sub)=>{
              if (checkedKeys.includes(sub.path)) children.push(sub)
            })
            if (children.length>0) checkedMenus.push({ path: menu.path, label: menu.label, children: children})
          }
        })
        console.log('checkedMenus', checkedMenus)
        await request("setRoleMenu", {
          roleId: state.roleId,
          menu: checkedMenus
        })
        state.dialogFormVisible = false
        getData()
      } catch (error) {
        console.error(error)
      }
    }

    setupInit()
    return {
      ...toRefs(state),
      getData,
      searchData,
      updatePage,
      addNew,
      editMenu,
      setRoleMenu
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
