<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="data.total" @update="updatePage">
      <el-table-column prop="userName" label="姓名" />
      <el-table-column prop="phoneNumber" label="电话">
        <template #default="{row}">
          {{ row.phoneNumber &&  (String(row.phoneNumber).slice(0,3)+'****'+String(row.phoneNumber).slice(7))}}
        </template>
      </el-table-column>
      <el-table-column prop="company" label="公司" />
      <el-table-column prop="robotId" label="机器人编码" />
      <el-table-column prop="status" label="状态" />
      <el-table-column prop="permissionStartTime" label="生效时间" />
      <el-table-column prop="permissionEndTime" label="失效时间" />
      <el-table-column label="操作" fixed="right" width="150px">
        <template #default="scope" style="padding-bottom: 20px;">
          <el-button type="primary" plain size="mini" @click="editDetail(scope.row)">修改</el-button>
          <el-button type="danger" plain size="mini" @click="deleteDetail(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog v-model="data.dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="120px">
        <el-form-item label="姓名">
          <el-input disabled v-model="detailform.userName" placeholder="姓名" />
        </el-form-item>
        <el-form-item label="电话">
          <el-input disabled v-model="detailform.phoneNumber" placeholder="电话" />
        </el-form-item>
        <el-form-item label="机器人编码">
          <el-input disabled v-model="detailform.robotId" placeholder="机器人编码" />
        </el-form-item>
        <el-form-item label="生效时间" prop="permissionStartTime">
          <el-date-picker
            v-model="detailform.permissionStartTime"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="生效时间"
            :disabled-date="disabledDate"
          />
        </el-form-item>
        <el-form-item label="失效时间" prop="permissionEndTime">
          <el-date-picker
            v-model="detailform.permissionEndTime"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="失效时间"
            :disabled-date="disabledDate"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="editSubmit">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { ref, reactive } from "@vue/reactivity";
import { ElMessage, ElMessageBox } from "element-plus";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const params = [
      {
        key: "userName",
        value: "",
        label: "姓名",
      },
      {
        key: "phoneNumber",
        value: "",
        label: "电话",
      },
      {
        key: "robotId",
        value: "",
        label: "机器人编码",
      }
    ];
    const detailform = reactive({
        id: '',
        userName: "",
        phoneNumber: "",
        robotId: "",
        permissionStartTime: "",
        permissionEndTime: ""
    })
    const detailformRef = ref(null)
    const detailformRules = reactive({
      permissionStartTime: {
        required: true,
        message: '请选择生效时间',
        trigger: 'change',
      },
      permissionEndTime: {
        required: true,
        message: '请选择失效时间',
        trigger: 'change',
      },
    })
    var query = {
      userName: "", // 姓名
      phoneNumber: "", // 电话
      robotId: "", // 机器人编码
    };
    const data = reactive({
      dialogFormVisible: false,
      page: 1,
      size: 10,
      total: 0,
    });
    const tableDataList = ref([]);

    const getData = async () => {
      console.log("query", query);
      try {
        const res = await request("permissionList", {
          ...query,
          page: data.page,
          size: data.size,
        });
        console.log("permissionList", res);
        tableDataList.value = res.records;
        data.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const searchData = (data) => {
      query = data;
      getData();
    };
    const updatePage = (page, size) => {
      data.page = page || 1;
      data.size = size || 10;
      getData();
    };
    const editDetail = async (row) => {
      try {
        for (let key of Object.keys(detailform)) {
          if (row[key]) {
            detailform[key] = row[key];
          } else {
            detailform[key] = "";
          }
        }
        detailform.id = row.id
        data.dialogFormVisible = true
        console.log("editDetail", row);
      } catch (error) {
        console.error(error);
      }
    };
    const deleteDetail = async (row) => {
      try {
        await ElMessageBox.confirm(
          '确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request("deletePermission", {
          id: row.id
        })
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "删除成功!"
        })
      } catch (error) {
        console.error(error)
      }
    } 
    const editSubmit = async () => {
      console.log('onSubmit', detailform)
      try {
        detailformRef.value && await detailformRef.value.validate()
        await request("changePermission", {
          id: detailform.id,
          permissionStartTime: detailform.permissionStartTime,
          permissionEndTime : detailform.permissionEndTime,
        })
        data.dialogFormVisible = false
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "修改成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const disabledDate = function(time) {
      return time.getTime() < (Date.now() - 86400000)
    }
    getData();
    return {
      params,
      query,
      data,
      tableDataList,
      detailform,
      detailformRef,
      detailformRules,
      getData,
      searchData,
      updatePage,
      editDetail,
      deleteDetail,
      editSubmit,
      disabledDate
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>