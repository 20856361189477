<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData">
      <template #handle>
        <el-form-item>
          <el-button type="primary" plain @click="exportData">表格导出</el-button>
        </el-form-item>
      </template>
    </Filter>
    <Table :tableData="tableDataList" :total="data.total" @update="updatePage">
      <el-table-column prop="registerTime" label="注册时间" />
      <el-table-column prop="userName" label="姓名" />
      <el-table-column prop="userType" label="状态" >
        <template #default="{row}">
          {{ userStatusMap[row.userType] }}
        </template>
      </el-table-column>
      <el-table-column prop="userLabel" label="用户标签" >
        <template #default="{row}">
          {{ row.userLabel?.map(item => userTypesMap[item]).join(', ') || '普通用户' }}
        </template>
      </el-table-column>
      <el-table-column prop="phoneNumber" label="电话" >
        <template #default="{row}">
          {{ row.phoneNumber &&  (String(row.phoneNumber).slice(0,3)+'****'+String(row.phoneNumber).slice(7))}}
        </template>
      </el-table-column>
      <el-table-column prop="email" label="邮箱" />
      <el-table-column prop="company" label="公司" />
      <el-table-column prop="userCompany" label="地区" >
        <template #default="{row}">{{ row.userCompany && `${row.userCompany.province??''}${row.userCompany.city??''}${row.userCompany.district??''}`  }}</template>
      </el-table-column>
      <el-table-column prop="userCompany" label="地址" >
        <template #default="{row}">{{ row.userCompany ? row.userCompany.address : '' }}</template>
      </el-table-column>
      <el-table-column prop="industryName" label="行业" />
      <el-table-column prop="registerWay" label="来源渠道">
        <template #default="{row}">{{ registerWayMap[row.registerWay] }}</template>
      </el-table-column>
      <el-table-column prop="role" label="角色" >
        <template #default="{row}">{{(row.role||[]).map((item)=>{return item.roleName}).join('，')}}</template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="180" >
        <template #default="{row}">
          <el-button type="primary" plain size="mini" @click="editRole(row)">修改角色</el-button>
          <el-button v-if="row.userType == 1" type="danger" plain size="mini" @click="disableDetail(row)">禁用</el-button>
          <el-button v-if="row.userType == 100" type="primary" plain size="mini" @click="enableDetail(row)">启用</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="修改角色" v-model="data.dialogRoleVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="form" class="dialog-form" :model="roleForm" label-width="100px">
        <el-form-item label="角色：">
          <el-select
            v-model="roleForm.roleId"
            placeholder="角色"
            multiple
            clearable
            style="width:100%;"
          >
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="标签：">
          <el-select
            v-model="roleForm.userLabel"
            placeholder="标签"
            clearable
            multiple
            style="width:100%;"
          >
            <el-option
              v-for="item in userTypesList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" size="mini" @click="onRoleSubmit">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { request, downloadFile } from "@/utils/request";
import { common } from "@/common/mixin";
import { ref, reactive } from "@vue/reactivity";
import { ElMessage, ElMessageBox } from "element-plus";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import { userTypesList, userTypesMap, userStatusList, userStatusMap, registerWayList, registerWayMap } from "@/common/constant";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const params = [
      {
        key: "userName",
        value: "",
        label: "姓名",
      },
      {
        key: "phoneNumber",
        value: "",
        label: "电话",
      },
      {
        key: "userType",
        type: 'Select',
        value: '',
        label: '用户状态',
        options: {
          data: userStatusList,
          label: 'name',
          value: 'value',
        },
      },
      {
        key: "userLabel",
        type: 'Select',
        value: '',
        label: '用户标签',
        options: {
          data: userTypesList,
          label: 'name',
          value: 'value',
        },
        attrs: {
          multiple: false
        }
      },
      {
        key: "registerWay",
        type: 'Select',
        value: '',
        label: '来源渠道',
        options: {
          data: registerWayList,
          label: 'name',
          value: 'value',
        },
      },
      {
        type: "Date",
        key: "startTime",
        value: '',
        label: "开始日期",
        options: {
          "type": "date",
          "format": "YYYY-MM-DD HH:mm:ss",
          "value-format": "YYYY-MM-DD HH:mm:ss",
          "placeholder": "开始日期",
        }
      },
      {
        type: "Date",
        key: "endTime",
        value: '',
        label: "结束日期",
        options: {
          "type": "date",
          "format": "YYYY-MM-DD HH:mm:ss",
          "value-format": "YYYY-MM-DD HH:mm:ss",
          "placeholder": "结束日期",
          "default-time": new Date(2000, 1, 1, 23, 59, 59)
        }
      },
    ];
    const form = reactive({
        userName: "",
        role: "",
        phoneNumber: "",
        email: "",
        company: "",
    })
    var query = {
      userName: "", // 姓名
      phoneNumber: "", // 电话
      // role: "", // 角色
    };
    const roleForm = reactive({
      userId: '',
      roleId: [],
      userLabel: [],
    })
    const data = reactive({
      dialogRoleVisible: false,
      page: 1,
      size: 10,
      total: 0,
    });
    const tableDataList = ref([])
    const roleList =  ref([])
    const getData = async () => {
      console.log("query", query)
      try {
        const res = await request("getUserList", {
          ...query,
          page: data.page,
          size: data.size,
        });
        console.log("getUserList", res);
        tableDataList.value = res.records;
        data.total = res.total;
      } catch (error) {
        console.error(error);
      }
    }
    const exportData = async () => {
      try {
        const res = await downloadFile("exportUserList", {}, {
          method: 'GET',
          params: query
        });
        // let blob = new Blob([res],{type: "application/octet-stream"})
        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '用户列表.xlsx'
          a.href = e.target.result
          a.click()
        }
      } catch (error) {
        console.error(error)
      }
    }
    const getRoleList = async () => {
      try {
        const res = await request("getRoleList", {
          page: 1,
          size: 9999,
        })
        roleList.value = res.records
      } catch (error) {
        console.error(error)
      }
    }
    const searchData = (data) => {
      query = data;
      getData();
    };
    const updatePage = (page, size) => {
      data.page = page || 1;
      data.size = size || 10;
      getData();
    };
    const disableDetail = async (row) => {
      try {
        await ElMessageBox.confirm(
          '确定禁用？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request('setInvalidUser', {
          id: row.id,
          userType: 100
        })
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const enableDetail = async (row) => {
      try {
        await ElMessageBox.confirm(
          '确定启用？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request('setInvalidUser', {
          id: row.id,
          userType: 1
        })
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const editRole = async (row) => {
      try {
        console.log("editRole", row);
        roleForm.userId = row.id
        roleForm.roleId = (row.role||[]).map((item)=>{
          return item.roleId
        })
        roleForm.userLabel = row.userLabel || [0]
        data.dialogRoleVisible = true
      } catch (error) {
        console.error(error);
      }
    };
    const onRoleSubmit = async function () {
      try {
        await request("setUserRole", roleForm)
        data.dialogRoleVisible = false
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "角色修改成功"
        })
      } catch (error) {
        console.error(error)
      }
    }
    getData()
    getRoleList()
    return {
      userTypesList,
      userTypesMap,
      userStatusMap,
      registerWayMap,
      params,
      query,
      data,
      tableDataList,
      roleList,
      form,
      roleForm,
      disableDetail,
      enableDetail,
      editRole,
      getData,
      exportData,
      searchData,
      updatePage,
      onRoleSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
