<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData" add @add="addNew"></Filter>
    <Table :tableData="tableDataList" :total="data.total" :select="false" @update="updatePage">
      <el-table-column prop="userName" label="姓名" />
      <!-- <el-table-column prop="role" label="角色" /> -->
      <el-table-column prop="phoneNumber" label="电话">
        <template #default="{row}">
          {{ row.phoneNumber &&  (String(row.phoneNumber).slice(0,3)+'****'+String(row.phoneNumber).slice(7))}}
        </template>
      </el-table-column>
      <el-table-column prop="company" label="公司" />
      <el-table-column prop="robotId" label="机器人编码" />
      <el-table-column prop="requestCreateTime" label="申请时间" />
      <el-table-column prop="startTime" label="生效时间" />
      <el-table-column prop="endTime" label="失效时间" />
      <el-table-column prop="requestStatus" label="状态" />
      <el-table-column label="操作" fixed="right" width="150">
        <template #default="{row}" style="padding-bottom: 20px;">
          <!-- <el-button v-if="row.requestStatus==='未通过'" type="danger" size="small" @click="deleteDetail(row)">删除</el-button> -->
          <el-button v-if="row.requestStatus==='未通过'" type="primary" plain size="mini" @click="editDetail(row)">修改</el-button>
          <el-button v-if="row.requestStatus==='未通过'" type="primary" plain size="mini" @click="handleDetail(row)">审批</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog v-model="data.dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="120px">
        <el-form-item label="电话" prop="phoneNumber">
          <el-input v-model="detailform.phoneNumber" placeholder="电话" />
        </el-form-item>
        <el-form-item label="机器人编码" prop="robotId">
          <el-input v-model="detailform.robotId" placeholder="机器人编码" />
        </el-form-item>
        <el-form-item label="生效时间" prop="startTime">
          <el-date-picker
            v-model="detailform.startTime"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="生效时间"
            :disabled-date="disabledDate"
          />
        </el-form-item>
        <el-form-item label="失效时间" prop="endTime">
          <el-date-picker
            v-model="detailform.endTime"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="失效时间"
            :disabled-date="disabledDate"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="editDetailSubmit">确认</el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="data.dialogTableVisible" destroy-on-close :modal="true" :append-to-body="true" @closed="closeApplyList">
      <template #title="scope">
        <el-button @click="showApplyform" type="primary" size="mini" plain>添加</el-button>
        <el-button @click="importFile" type="primary" size="mini" plain>导入Excel</el-button>
        <input ref="inputFile" type="file" style="display:none;" @input="uploadExcel">
        <el-button @click="downloadExcel(scope.row)" type="success" size="mini" style="margin-left: 50px;">下载Excel模板</el-button>
      </template>
      <el-table class="dialog-table" height="500px" :data="applyList" border stripe>
        <el-table-column prop="phoneNumber" label="电话" ></el-table-column>
        <el-table-column prop="robotId" label="机器人编码"></el-table-column>
        <el-table-column prop="startTime" label="生效时间" />
        <el-table-column prop="endTime" label="失效时间" />
        <el-table-column label="操作" width="120px">
          <template #default="scope">
            <el-button @click="deleteApplyform(scope.$index)" type="danger" plain size="mini">删除</el-button>
            <!-- <el-button @click="editApplyform(scope.$index)" size="mini">修改</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button size="small" type="primary" @click="submitApply">提交申请</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { ref, reactive } from "@vue/reactivity";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import { ElMessage } from "element-plus";
import XLSX from 'xlsx';
import { formatTime } from "@/common/utils";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const params = [
      {
        key: "userName",
        value: "",
        label: "姓名",
      },
      {
        key: "phoneNumber",
        value: "",
        label: "电话",
      },
      {
        key: "robotId",
        value: "",
        label: "机器人编码",
      }
    ];
    const applyList = reactive([]) // 申请列表
    var query = {
      userName: "", // 姓名
      phoneNumber: "", // 电话
      robotId: "", // 机器人编码
    };
    const inputFile = ref(null)
    const data = reactive({
      dialogFormVisible: false,
      dialogTableVisible: false,
      page: 1,
      size: 10,
      total: 0,
    });
    const tableDataList = ref([]);
    const detailform = reactive({
        id: '',
        phoneNumber: "",
        robotId: "",
        startTime: "",
        endTime: ""
    })
    const detailformRef = ref(null)
    const detailformRules = reactive({
      phoneNumber: {
        required: true,
        message: '请输入电话号码',
        trigger: 'blur',
      },
      robotId: {
        required: true,
        message: '请输入机器人编码',
        trigger: 'blur',
      },
      startTime: {
        required: true,
        message: '请选择生效时间',
        trigger: 'change',
      },
      endTime: {
        required: true,
        message: '请选择失效时间',
        trigger: 'change',
      },
    })
    const getData = async () => {
      console.log("query", query);
      try {
        const res = await request("requestList", {
          ...query,
          page: data.page,
          size: data.size,
        });
        console.log("requestList", res);
        tableDataList.value = res.records;
        data.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const searchData = (data) => {
      query = data;
      getData();
    };
    const updatePage = (page, size) => {
      data.page = page || 1;
      data.size = size || 10;
      getData();
    };
    const deleteDetail = async (row) => {
      try {
        await request("deletePermission", {
          id: row.id
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "删除成功!"
        })
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    const editDetail = async (row) => {
      try {
        for (let key of Object.keys(detailform)) {
          if (row[key]) {
            detailform[key] = row[key];
          } else {
            detailform[key] = "";
          }
        }
        detailform.id = row.id
        data.dialogFormVisible = true
        console.log("editDetail", row);
      } catch (error) {
        console.error(error);
      }
    }
    const editDetailSubmit = async () => {
      console.log('editDetailSubmit', detailform)
      try {
        detailformRef.value && await detailformRef.value.validate()
        if (detailform.id) {
          await request("updatePermissionRequest", {
            ...detailform,
            requestId: detailform.id
          })
          getData()
          ElMessage({
            showClose: true,
            type: "success",
            message: "提交成功!"
          })
        } else {
          applyList.push({
            phoneNumber: detailform.phoneNumber,
            robotId: detailform.robotId,
            startTime: detailform.startTime,
            endTime: detailform.endTime
          })
          // await request("permissionRequest", detailform)
        }
        data.dialogFormVisible = false
      } catch (error) {
        console.error(error)
      }
    }
    const handleDetail = async (row) => {
      try {
        console.log('handleDetail', row)
        await request("addPermission", {
          id: row.id
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "审批成功!",
        });
        getData();
      } catch (error) {
        console.error(error);
      }
    };
    const selectHandle = async (val) => {
      try {
        console.log('selectHandle', val)
        const params = val.map((item => {
          return { id: item.id}
        }))
        console.log(params)
        await request("addPermissionBatch", params)
        ElMessage({
          showClose: true,
          type: "success",
          message: "审批成功!",
        });
        getData();
      } catch (error) {
        console.error(error);
      }
    }
    const addNew = () => {
      data.dialogTableVisible = true
      // data.dialogFormVisible = true
      // for (let key of Object.keys(detailform)) {
      //   detailform[key] = ""
      // }
    }
    const closeApplyList = () => {
      applyList.length = 0
    }
    const showApplyform = () => {
      for (let key of Object.keys(detailform)) {
        detailform[key] = ""
      }
      data.dialogFormVisible = true
    }
    function formatExcelDate (date) {
      let result = ''
      if (typeof date === 'number') {
        const d = new Date(1900, 0, date-1)
        result = formatTime(d)
      } else {
        result = date
      }
      result = result.replace(/(-|\/)/g, '')
      result = result.slice(0,4) + '-' + result.slice(4,6) + '-' + result.slice(6,8)
      return result
    }
    const uploadExcel = (event) => {
      const file = event.target.files[0]
      const fr = new FileReader()
      fr.readAsArrayBuffer(file)
      fr.onload = (e) => {
        const data = e.target.result
        console.log(data)
        const workbook = XLSX.read(data, { type: 'array' })
        console.log(workbook)
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const results = XLSX.utils.sheet_to_json(worksheet)
        results.forEach(item => {
          item.phoneNumber = String(item.phoneNumber)
          item.startTime = formatExcelDate(item.startTime)
          item.endTime = formatExcelDate(item.endTime)
        })
        console.log('XLSX', results)
        applyList.push(...results)

        event.target.value = null
      }
    }
    const downloadExcel = () => {
      let aoa = [
        ['phoneNumber', 'robotId', 'startTime', 'endTime'],
        ['18012345678', 'Zu10000', "2020-10-01", '2030-10-01']
      ]
      let sheet = XLSX.utils.aoa_to_sheet(aoa);
      console.log('downloadExcel', sheet)
      let blob = sheet2blob(sheet)
      console.log('sheet: ', sheet)
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        let a = document.createElement('a')
        a.download = '模板.xlsx'
        a.href = e.target.result
        a.click();
      }
    }
    const editApplyform = (index) => {
      const row = applyList[index]
      for (let key of Object.keys(detailform)) {
        if (row[key]) {
          detailform[key] = row[key]
        }
      }
      data.dialogFormVisible = true
    }
    const deleteApplyform = (index) => {
      applyList.splice(index,1)
    }
    const submitApply = async function () {
      try {
        if (applyList.length<=0) {
          return
        }
        await request("permissionRequestBatch", applyList)
        applyList.length = 0
        data.dialogTableVisible = false
        ElMessage({
          showClose: true,
          type: "success",
          message: "申请成功!",
        });
        getData();
      } catch (error) {
        applyList.length = 0
        console.error(error)
      }
    }
    const disabledDate = function(time) {
      return time.getTime() < (Date.now() - 86400000)
    }
    const importFile = function() {
      console.log('data.inputFile', inputFile)
      inputFile.value.click()
    }
    function sheet2blob(sheet, sheetName) {
      sheetName = sheetName || 'sheet1';
      var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
      };
      workbook.Sheets[sheetName] = sheet;
      // 生成excel的配置项
      var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
      };
      var wbout = XLSX.write(workbook, wopts);
      var blob = new Blob([s2ab(wbout)], {type:"application/octet-stream"});
      // 字符串转ArrayBuffer
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }
      return blob;
    }
    getData();
    return {
      params,
      query,
      data,
      tableDataList,
      applyList,
      detailform,
      detailformRef,
      detailformRules,
      inputFile,
      getData,
      searchData,
      updatePage,
      deleteDetail,
      editDetail,
      editDetailSubmit,
      handleDetail,
      selectHandle,
      addNew,
      closeApplyList,
      showApplyform,
      uploadExcel,
      downloadExcel,
      editApplyform,
      deleteApplyform,
      submitApply,
      disabledDate,
      importFile
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
